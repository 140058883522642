import * as React from "react";
import {
	Row,
	Col,
	Container,
} from "@mui/material";

import './home.css';
import { boldRaleway, button, cardMedia, raleway } from "./styles";
import HomeIMG from './home-img.svg';
import AboutImg from './about-img.svg';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

import videoSource from './ai-assistant.mp4';


function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const Register = () => {
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	return (

		<Box className="home">
			<Grid container>
				<Grid item xs={12} sm={6} >
					<img src={AboutImg} />
				</Grid>
				<Grid item xs={12} sm={6}  >
					<Box className="content" marginTop={12}>
						<h3 className=""> <span> ThincHealth: </span>Your Partner in Health and Wellness</h3>
						<h4>Embrace the future of healthcare – personalized, accessible, and intelligent with AI and Telemedicine.</h4>
						{/* <a href="#" class="btn"> contact us <span class="fas fa-chevron-right"></span> </a> */}
						{/* <Container> */}
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }} marginTop={6}>
							<Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
								<Tab label="Telemedicine" {...a11yProps(0)} />
								<Tab label="Remote Patient Monitoring" {...a11yProps(1)} />
								<Tab label="DR. AI Assistant" {...a11yProps(2)} />
								<Tab label="ThincHealth AI" {...a11yProps(3)} />
								<Tab label="Healthcare Integration" {...a11yProps(4)} />
							</Tabs>
						</Box>
						<TabPanel value={value} index={0}>
							<h5>ThincHealth's telehealth platform allows patients to consult with doctors from the comfort of their homes, and its remote patient monitoring capabilities enable health professionals to monitor patients' health conditions remotely</h5>
							<Grid container direction="row" spacing={{ xs: 1, md: 2 }} >
								{/* <Grid item >
									<a href="/doctor_signup" className="btn">
										Sign Up Doctor
									</a>
								</Grid>

								<Grid item >
									<a href="/doctor_signin" className="btn">
										Log In Doctor
									</a>
								</Grid>

								<Grid item>
									<a href="/patient_signup" className="btn">
										Sign Up Patient
									</a>
								</Grid>

								<Grid item >
									<a href="/patient_signin" className="btn">
										Log In Patient
									</a>
								</Grid> */}

								<Grid item >
									<a href="/admin_signin" className="btn">
										Log In Admin
									</a>
								</Grid>
								<Grid item >
									<a href="https://www.thinchealth.co/contact" className="btn">
										Contact us to get Credentials
									</a>
								</Grid>

							</Grid>
						</TabPanel>

						<TabPanel value={value} index={1}>
							<h5>1.3 billion people face daily challenges with chronic conditions like hypertension. Our telehealth solution, integrated with medication tracking and personalized health data monitoring via kits and Apple Watch, empowers patients to better manage their health from home. All health data is reviewed by doctors via our Telemedicine app, streamlining the process and minimizing the need for in-person visits. We're making remote health management more efficient and effective.</h5>
							<a href="https://www.thinchealth.co/contact" className="btn" target="_blank">
								Contact Us to Learn more
							</a>
						</TabPanel>

						<TabPanel value={value} index={2}>
							<h5> Currently, we are working on the development of our Digital Personal AI Assistant, designed for both your convenience and the needs of healthcare providers. Stay tuned !!</h5>
							<div>
								<video width="500" height="500" autoPlay loop muted>
									<source src={videoSource} type="video/mp4" />
									Your browser does not support the video tag.
								</video>
							</div>
							<a href="https://www.thinchealth.co/contact" className="btn" target="_blank">
								Contact Us to Learn more
							</a>
						</TabPanel>

						<TabPanel value={value} index={3}>
							<h5>Enhancing Medical Practices with AI Integration for Healthcare Providers</h5>
							<a href="https://ai.thinchealth.co" className="btn" target="_blank">
								Our Website
							</a>
						</TabPanel>


						<TabPanel value={value} index={4}>
							<h5>Contact us to learn more about how we can assist healthcare providers in integrating AI into their daily practices.</h5>
							<a href="https://www.thinchealth.co/contact" className="btn" target="_blank">
								Contact Us to Learn more
							</a>
						</TabPanel>

					</Box>

				</Grid>
			</Grid>
		</Box>
	);
};


export default Register;
